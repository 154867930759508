import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./Components/Layout/Layout";
import Home from "./Pages/HomePage/Home";
import AboutPage from "./Pages/AboutPage/AboutPage";
import Career from "./Pages/Career/Career";
import Contact from "./Pages/Contact/Contact";
import Webdevelopment from "./Pages/ServicesPages/Webdevelopment";
import AppDevelopment from "./Pages/ServicesPages/MobileAppDevelopment";
import DigitalMarketing from "./Pages/ServicesPages/DigitalMarketing";
import CMSdevelopmet from "./Pages/ServicesPages/CMSdevelopmet";
import HealthandFitness from "./Pages/IndustriesPage/HealthandFitness";
import BusinessAppdev from "./Pages/IndustriesPage/BusinessAppdev";
import ITESDevelopment from "./Pages/IndustriesPage/ITESDevelopment";
import CRMDevelopment from "./Pages/ServicesPages/CRMDevelopment";
import TravelApp from "./Pages/IndustriesPage/TravelApp";
import MediaEntertainment from "./Pages/IndustriesPage/MediaEntertainment";
import ERPDevlopment from "./Pages/ServicesPages/ERPDevlopment";
import SportsAppDev from "./Pages/IndustriesPage/SportsAppDev";
import EducationAppdev from "./Pages/IndustriesPage/EducationAppdev";
import FinanceAppdev from "./Pages/IndustriesPage/FinanceAppdev";
import RealEstateAppdev from "./Pages/IndustriesPage/RealEstateAppdev";
import EcommerceAppDev from "./Pages/IndustriesPage/EcommerceAppDev";
import SocialMediaAppDev from "./Pages/IndustriesPage/SocialMediaAppDev";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";

import ReactGA from "react-ga";


ReactGA.initialize("G-5CHDVVN4Q7");


function App() {   
  return (
    <div className="App">
      <Routes>
     
          <Route  element={<Layout />}>
       
          {/* Pages */}
          <Route index path="/"  element={<Home />} />
          <Route path="Home" element={<Home />} />
          <Route path="/About-us" element={<AboutPage />} />
          <Route path="Career" element={<Career />} />
          <Route path="Contact-us" element={<Contact />} />
          <Route path="Privacy-Policy" element={<PrivacyPolicy/>} />

          {/* SERVICES PAGE */}
          <Route path="erp-software-development" element={<ERPDevlopment />}/>
          <Route path="crm-development-service" element={<CRMDevelopment/>}/>
          <Route path="web-application-development" element={<Webdevelopment/>}/>
          <Route path="cms-software-development" element={<CMSdevelopmet/>} />
          <Route path="mobile-app-development" element={<AppDevelopment/>}/>
          <Route path="digital-marketing-agency" element={<DigitalMarketing />}
          />
          {/* Industries Page */}

          <Route 
            path="travel-app-development"
            element={<TravelApp />}
          />
          <Route
            path="sports-app-development-company"
            element={<SportsAppDev />}
          />
          <Route
            path="healthcare-mobile-app-development"
            element={<HealthandFitness />}
          />
          <Route
            path="business-apps-development"
            element={<BusinessAppdev />}
          />
          <Route path="ITES-service" element={<ITESDevelopment />} />
          <Route
            path="media-entertainment-app-development"
            element={<MediaEntertainment />}
          />
          <Route
            path="education-mobile-app-development"
            element={<EducationAppdev />}
          />
          <Route path="mobile-banking-app-development" element={<FinanceAppdev />} />
          <Route
            path="best-real-estate-app-development"
            element={<RealEstateAppdev />}/>
          <Route
            path="ecommerce-mobile-app-development"
            element={<EcommerceAppDev />}
          />
          <Route
            path="social-app-development"
            element={<SocialMediaAppDev />}/>
          

        </Route>
      </Routes>
    </div>
  );
}


export default App;
